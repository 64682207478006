import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { io } from 'socket.io-client';

const Screen = () => {
    const socket = io("http://localhost:8000");

    const [message, setMessage] = useState('');

    // Listen for the 'message' event from the server
    useEffect(() => {
        socket.on("messageBroadcast", (message) => {
            console.log(`Received from server: ${message}`);
            setMessage(message);

        });
    });


    return (
        <div>
            <nav>
                <ul>
                    <li>
                        <Link to="/student">Student</Link>
                    </li>
                    <li>
                        <Link to="/teacher">Teacher</Link>
                    </li>
                </ul>
            </nav>
            {message}

        </div>
    )
}

export default Screen
