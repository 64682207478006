import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import "./App.css";
import Dictaphone from "./Dictaphone";
import { io } from "socket.io-client";
import Screen from "./Screen";

function App() {

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/student" element={<Screen/>} />
          <Route path="/teacher" element={<Dictaphone />} />
          <Route path="/" element={<Dictaphone />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
