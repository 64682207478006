import axios from 'axios';
import React, { useEffect, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { io } from 'socket.io-client';


const Dictaphone = () => {
  const [message, setMessage] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [translatedWordText, setTranslatedWordText] = useState('');
  const [translated5WordText, setTranslated5WordText] = useState('');

  //  const socket = io("https://ts-socket.tech-and-beyond.org");

  const getLastNWords = (inputString, n) => {
    const words = inputString.split(' ');
    if (words.length >= n) {
      translate(words.slice(-n).join(' ')).then(res => {
        setTranslated5WordText(res)
      })
      return words.slice(-n).join(' ');
    } else {
      translate(inputString).then(res => {
        setTranslated5WordText(res)
      })
      return inputString;
    }
  }

  const commands = [
    {
      command: 'reset',
      callback: () => resetTranscript()
    },
    {
      command: 'shut up',
      callback: () => setMessage('I wasn\'t talking.')
    },
    {
      command: 'Hello',
      callback: () => setMessage('Hi there!')
    },
  ]

  const {
    transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    listening,
  } = useSpeechRecognition({ commands });

  useEffect(() => {
    if (transcript !== '') {
      console.log('Real-time transcript:', transcript);
      translate(transcript).then(res => {
        setTranslatedWordText(res)
      })
      // socket.emit('message', transcript); // Emit text to the server
    }
  }, [transcript]);

  useEffect(() => {
    if (finalTranscript !== '') {
      console.log('Got final result:', finalTranscript);
      translate(finalTranscript).then(res => {
        setTranslatedText(res)
      })
    }
    if (interimTranscript !== '') {
      console.log('Got interim result:', interimTranscript);
      //  translate(finalTranscript).then(res => {
      //     setTranslatedText(res)
      //  })
    }
  }, [interimTranscript, finalTranscript]);
  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null;
  }

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    console.log('Your browser does not support speech recognition software! Try Chrome desktop, maybe?');
  }



  const listenContinuously = () => {
    SpeechRecognition.startListening({
      continuous: true,
      interimResults: true,
      //  language: 'ar-KW',
      language: 'en-EN',
    });
  };

  const translate = async (text) => {
    const options = {
      method: 'POST',
      url: 'https://microsoft-translator-text.p.rapidapi.com/translate',
      params: {
        'to[0]': 'ar',
        'api-version': '3.0',
        profanityAction: 'NoAction',
        textType: 'plain'
      },
      headers: {
        'content-type': 'application/json',
        'X-RapidAPI-Key': '1811d2a188msh0705d23e6c73db3p1785c3jsnec71b517ff21',
        'X-RapidAPI-Host': 'microsoft-translator-text.p.rapidapi.com'
      },
      data: [
        {
          Text: text
        }
      ]
    };

    try {
      const response = await axios.request(options);
      return response.data[0].translations[0].text;
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      <div>
        <span>
          listening:
          {' '}
          {listening ? 'on' : 'off'}
        </span>
        <div>
          <button type="button" onClick={resetTranscript}>Reset</button>
          <button type="button" onClick={listenContinuously}>Listen</button>
          <button type="button" onClick={SpeechRecognition.stopListening}>Stop</button>
        </div>
      </div>
      <div style={{ marginBlock: 4, color: 'blue' }}>
        Speaker Word transcript(English) : <br />{transcript}
      </div>
      <div style={{ marginBlock: 4, color: 'green' }}>
        Translated arabic Text Word transcript : <br />{translatedWordText}
      </div>
      <br />
      <div style={{ marginBlock: 4, color: 'blue' }}>
        Speaker Sentence transcript(English) : <br />{finalTranscript}
      </div>
      <div style={{ marginBlock: 4, color: 'green' }}>
        Translated arabic Sentence transcript : <br />{translatedText}
      </div>
      <br />
      <div style={{ marginBlock: 4, color: 'red' }}>
        Interim transcript(English) : <br />{interimTranscript}
      </div>
      <br />
      <div style={{ marginBlock: 4, color: 'blue' }}>
        Speaker Last 5 words transcript(English) : <br />{getLastNWords(finalTranscript,5)}
      </div>
      <div style={{ marginBlock: 4, color: 'green' }}>
        Translated arabic last 5 words transcript : <br />{translated5WordText}
      </div>

    </div>
  );
};

export default Dictaphone;